<template>
  <div>
    {{ formattedDate() }}
  </div>
</template>

<script>
import { isValidDate, formatDate } from "../../../helpers/common";
export default {
  name: "TableDate",

  props: {
    field: {
      type: String,
      required: true,
    },
    format: {
      type: String,
      default: "dd-MM-yyyy · HH:mm",
    },
  },

  methods: {
    formattedDate() {
      const date = new Date(this.data[this.field]);
      return isValidDate(date) ? formatDate(date, this.format) : "--";
    },
  },
};
</script>
